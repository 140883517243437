require('normalize.css/normalize.css');
require('../css/main.scss');
require('../css/carousel.scss');
require('../css/modal.scss');
require('./page.scss');

require('bootstrap');
import PopperJs from 'popper.js';
import jquery from 'jquery';


jquery(()=>{

    //jquery("#index-body").show();

    jquery("a").on('click', function(event) {

        // Make sure this.hash has a value before overriding default behavior
        if (this.hash !== "") {
          // Prevent default anchor click behavior
          event.preventDefault();

          // Store hash
          var hash = this.hash;

          // Using jQuery's animate() method to add smooth page scroll
          // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
          jquery('html, body').animate({
            scrollTop: jquery(hash).offset().top
        }, 400, 'swing', function(){

            // Add hash (#) to URL when done scrolling (default click behavior)
            window.location.hash = hash;
          });
        } // End if
  });


});
